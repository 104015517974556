<template>
  <PageLayout title="Delivery Companies">
    <div class="flex justify-between items-center">
      <form class="flex w-full items-center space-x-3">
        <div class="relative w-full max-w-sm">
          <span class="absolute inset-y-2.5 left-3">
            <MagnifyingGlassIcon class="w-5 h-5 text-gray-600"/>
          </span>
          <a-input v-model:value="query" allow-clear @input="debouncedSearch" placeholder="Search..."/>
        </div>
      </form>
      <a-button type="primary" class="bg-blue-500 hover:bg-blue-700 text-white" @click="showDrawer">
        New Company
      </a-button>
    </div>

    <a-table
        class="mt-4" :columns="DeliveryColumns"
        :loading="pageLoading"
        :pagination="{
                 current: current,
                 pageSize: pageSize,
                 total: totalItem,
                 showTotal: (total) => `Total ${total} items`
               }"
        :data-source="deliveryCompanies" bordered
        @change="handlePagination">
      <template #bodyCell="{ column, text, record }">
        <template v-if="['updatedAt', 'createdAt'].includes(column.dataIndex)">
          <div>
            {{ timestampToDate(text) }}
          </div>
        </template>

        <template v-else-if="column.dataIndex === 'link'">
          <a-button type="link" :href="record.link" target="_blank">
            <LinkIcon class="h-5 w-5" aria-hidden="true"/>
          </a-button>
        </template>

        <template v-else-if="column.dataIndex === 'logo'">
          <a-image
              :width="40"
              src="https://admin-ui.dev.speedma.net/img/logo.86ee88b7.png"
              :preview="{
                src: 'https://admin-ui.dev.speedma.net/img/logo.86ee88b7.png',
              }"
          />
        </template>

        <template v-else-if="column.dataIndex === 'countries'">
            <span>
              <a-tag
                  v-for="country in record.countries"
                  :key="country"
                  :color=" country.length > 5 ? 'geekblue' : 'green'"
              >
                {{ country?.countryName?.toUpperCase() }}({{ country?.flag }})
              </a-tag>
            </span>
        </template>

        <template v-else-if="column.dataIndex === 'operation'">
          <div class="space-x-2">
            <button type="button" @click.prevent="handleEditCompany(record)"
                    class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs leading-4 text-gray-700 hover:bg-gray-50 focus:outline-none disabled:cursor-not-allowed disabled:opacity-30">
              Edit<span class="sr-only">, {{ record.title }}</span>
            </button>
          </div>
        </template>
      </template>
    </a-table>

    <a-drawer
        :title="selectedCompany ? 'Edit Company' : 'Create a new company'"
        :width="520"
        :open="open"
        :body-style="{ paddingBottom: '80px' }"
        :footer-style="{ textAlign: 'right' }"
        @close="onClose"
    >
      <a-drawer v-model:open="typeDrawer" title="New company type" width="320" :closable="false">
        <a-form
            @finish="createDeliveryCompanyType"
            :model="typeForm"
            :rules="typeFormRules" layout="vertical">
          <a-form-item label="Name" name="name">
            <a-input v-model:value="typeForm.name" placeholder="Please enter name"/>
          </a-form-item>
          <a-form-item
              label="Description" name="description">
            <a-textarea
                v-model:value="typeForm.description"
                :rows="4"
                placeholder="please enter description"
            />
          </a-form-item>
          <div class="space-x-4">
            <a-button @click="showTypeDrawer">Cancel</a-button>
            <a-button type="primary" html-type="submit" :disabled="isLoadingType" :loading="isLoadingType"
                      class="bg-blue-500 hover:bg-blue-700 text-white">Submit
            </a-button>
          </div>
        </a-form>
      </a-drawer>
      <a-form
          @finish="submitForm"
          :model="form"
          :rules="rules" layout="vertical">
        <a-form-item label="Logo">
          <a-upload
              name="avatar"
              list-type="picture-card"
              :customRequest="customRequest"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :file-list="fileList"
              @change="handleChange"
          >
            <loading-outlined v-if="loading"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
          </a-upload>

        </a-form-item>

        <a-form-item label="Name of company" name="name">
          <a-input v-model:value="form.name" placeholder="Please enter company name"/>
        </a-form-item>
        <a-form-item label="Countries" name="countries">
          <a-select show-search
                    mode="multiple"
                    :filter-option="filterOption"
                    :options="countriesList"
                    v-model:value="form.countries"
                    allowClear placeholder="Search...">
            <template #option="{ value: val, label, icon }">
              <span role="img" :aria-label="val">{{ icon }}</span>
              &nbsp;&nbsp;{{ label }}
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="Type" name="typeId">
          <template #extra>
            <a-button @click="showTypeDrawer"
                      :disabled="isLoadingType" :loading="isLoadingType"
                      class="bg-blue-500 mt-2 hover:bg-blue-700 text-white" type="link">
              Add Type
            </a-button>
          </template>
          <a-select show-search
                    :filter-option="filterOption"
                    :options="deliveryCompaniesTypes"
                    v-model:value="form.typeId"
                    allowClear placeholder="Search...">
            <template #option="{ value: val, label, icon }">
              <span role="img" :aria-label="val">{{ icon }}</span>
              &nbsp;&nbsp;{{ label }}
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="Link" name="link">
          <a-input v-model:value="form.link" placeholder="Please enter link"/>
        </a-form-item>
        <a-form-item label="Description" name="description">
          <a-textarea
              v-model:value="form.description"
              :rows="4"
              placeholder="please enter  description"
          />
        </a-form-item>
        <div>
          <a-space>
            <a-button @click="onClose">Cancel</a-button>
            <a-button type="primary" html-type="submit" class="bg-blue-500 hover:bg-blue-700 text-white">Submit
            </a-button>
          </a-space>
        </div>
      </a-form>
    </a-drawer>
  </PageLayout>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import PageLayout from "@/layouts/PageLayout.vue";
import { LinkIcon, MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
import { debounce } from "lodash";
import { DeliveryColumns } from "@/services/catalogue/deliveryCompany/config";
import { message } from 'ant-design-vue';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { unixTimestampToDate } from '@/use/utils';
import {useRoute, useRouter} from "vue-router";

const store = useStore();
const router = useRouter()
const route = useRoute()
const loading = ref(false);
const form = reactive({
  countries: [],
  name: null,
  link: null,
  typeId: null,
  description: null,
});

const typeForm = reactive({
  name: null,
  description: null,
});

const rules = {
  countries: [{ required: true, message: 'Please select countries' }],
  name: [{ required: true, message: 'Please enter company name' }],
  typeId: [{ required: true, message: 'Please choose the type' }],
  description: [{ required: true, message: 'Please enter description' }],
};

const typeFormRules = {
  name: [{ required: true, message: 'Please enter name' }],
  description: [{ required: true, message: 'Please enter description' }],
};

const open = ref(false);
const typeDrawer = ref(false);
const showTypeDrawer = () => {
  typeDrawer.value = !typeDrawer.value;
};
const showDrawer = () => {
  open.value = true;
};

const fileList = ref([]);
const DeliveryCompanyType = ref("BUSINESS");
const query = ref('');
const current = ref(1);
const pageSize = ref(10);
const selectedCompany = ref(null);

const isLoadingType = computed(() => store.getters['DeliveryCompanyStore/loadingType']);
const deliveryCompanies = computed(() => store.getters['DeliveryCompanyStore/deliveryCompanies']);
const pageLoading = computed(() => store.getters['DeliveryCompanyStore/loading']);
const totalItem = computed(() => store.getters['DeliveryCompanyStore/totalItem']);
const countries = computed(() => store.getters['CountriesStore/countries']);
const deliveryCompaniesTypes = computed(() => store.getters['DeliveryCompanyStore/deliveryCompaniesTypes']);

const countriesList = computed(() => countries.value?.map(country => ({
  value: country.countryId,
  label: country.countryName
})) || []);

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG files!');
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must be smaller than 2MB!');
    return false;
  }
  return true;
};

const handleChange = (info) => {
  const { file } = info;

  if (file.status === 'done') {
    message.success(`${file.name} uploaded successfully.`);
  } else if (file.status === 'error') {
    message.error(`${file.name} upload failed.`);
  }
};

const customRequest = ({  file }) => {
  const formData = new FormData();
  formData.append('file', file);

  console.log(formData);

  //TODO: submit file and update company
};

const filterOption = (input, option) => {
  return option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const handlePagination = (pagination) => {
  const { current: currentPage, pageSize: size } = pagination;
  current.value = currentPage || 1;
  pageSize.value = size || 20;

  store.dispatch('DeliveryCompanyStore/getDeliveryCompanies', {
    page: current.value - 1,
    size: pageSize.value,
  });

  router.push({query: {p: current.value}})
};

const onClose = () => {
  open.value = false;
  resetForm();
};

const search = () => {
  store.dispatch('DeliveryCompanyStore/getDeliveryCompanies', {
    q: query.value,
  });
};

const debouncedSearch = debounce(() => {
  search();
}, 500);

const handleEditCompany = (company) => {
  selectedCompany.value = company;
  form.name = company.name;
  form.countries = company.countries.map(country => country.countryId);
  form.typeId = null;
  form.link = company.link;
  form.description = company.description;
  open.value = true;
};

const editDeliveryCompany = (form) => {
  store.dispatch('DeliveryCompanyStore/updateDeliveryCompany', {
    form: {
      ...form,
      id: selectedCompany.value.id,
    },
    params: {
      type: DeliveryCompanyType.value,
    },
  }).then(() => {
    open.value = false;
    selectedCompany.value = null;
  });
};

const createDeliveryCompanyType = (form) => {
  store.dispatch('DeliveryCompanyStore/createDeliveryCompanyType', form).then(() => {
    typeDrawer.value = false;
  });
};

const submitForm = () => {
  if (selectedCompany.value) {
    editDeliveryCompany(form);
  } else {
    createDeliveryCompany(form);
  }
  resetForm();
};

const resetForm = () => {
  form.countries = [];
  form.name = null;
  form.link = null;
  form.typeId = null;
  form.description = null;
  selectedCompany.value = null;
};

const createDeliveryCompany = (form) => {
  store.dispatch('DeliveryCompanyStore/createDeliveryCompany', form).then(() => {
    open.value = false;
  });
};

const timestampToDate = (d) => unixTimestampToDate(d, 'en');

/*const deleteDeliveryCompany = (id) => {
  store.dispatch('DeliveryCompanyStore/deleteDeliveryCompany', {
    value: id,
    params: {
      type: DeliveryCompanyType.value
    }
  });
};*/

onMounted(() => {
  current.value = Number(route.query?.p) || 1;

  store.dispatch('CountriesStore/getCountries');
  store.dispatch('DeliveryCompanyStore/getDeliveryCompaniesTypes');
  store.dispatch('DeliveryCompanyStore/getDeliveryCompanies', {
    page: current.value - 1,
    size: pageSize.value,
  });
});
</script>

<style scoped>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>